<template>
  <div class="hello">
    <h1 style="text-align: center">{{ msg }}</h1>
    <div class="controls">
      <button @click="togglePlay">
        {{ isPlaying ? '暂停播放' : '播放文字' }}
      </button>
      <button @click="changeSpeed">倍速播放 ({{ speed }}x)</button>
      <button @click="resetSpeed">恢复正常速度</button>
      <button @click="toggleLoop">
        {{ isLooping ? '关闭循环' : '开启循环' }}
      </button>
    </div>
    <ul style="list-style: none">
      <li>
        1、锚定赚钱效应，情绪，筹码价位就行了，而不是股价，特别是赚钱效应，当没有跌停的时候就是情绪好转的时候，这个时候可以尝试了,情绪都是有周期的,
        我经常说赚钱都是市场给的，至于市场怎么给呢？那就是赚钱效应，至于筹码，那就是这个股票不能有太多的套牢盘，有套牢盘的话谁去给他们解套呢？要思考这个问题
      </li>
      <li>
        2、92科比说的周期理论，主升，高位震荡，主跌，低位试错，当进入主跌行情的时候也就是有七八个股票跌停赚钱效应不好的时候，这个时候就考虑空仓休息了，这个时间只要大环境不是很恶劣一般都要三天差不多，然后等主跌周期结束之后，就可以开始低位试错了
      </li>
      <li>
        3、如果要进攻做右侧就要锚定五日线和十日线，破位了就要走就不能看了，如果想要防守做左侧，就要锚定20日线和60日线，如果破了20日线就要往60日线看了，做交易不能只做防守，不做进攻，这也是很重要的一点
      </li>
      <li>
        4、如果做右侧也就是锚定5日线和10日线的操作，当拉出最后一根大阳线的时候，然后隔天出现阴线的时候，这个时候是千万不能买，在这个位置要在最后一根阳线的基础上观察四到五天，看能不能站稳10日线，如果能站稳不创新低就可以买入了
      </li>
      <li>
        5、玩A股玩的就是情绪，自己可以好好感受一下是不是，所以行情不好千万不要有赚钱的想法，可以考虑休息一下
      </li>
      <li>
        6、玩A股就像是打游戏，必须注意细节，一场比赛想要赢，必须猥琐发育，注意各种细节，不然浪一波就要功亏一篑，本来赢的局面变成了输的局面，如果一直连败就要考虑休息一下
      </li>
      <li>7、玩A股一定要管住手，否则盈利一下就能给你干回去</li>
      <li>
        8、行情不好的时候，不亏，小亏都是赚，你想一想，如果你没有管住手，当天亏钱了，你第二天大概率还要亏钱，相当于你如果没有管住手你要亏两次钱
      </li>
      <li>
        9、买股票一定要买向上趋势的，千万不要买向下趋势的，想一下，一只股票想赚钱，必须拉升，拉升他就意味肯定是向上走的，向上都是获利盘，而向下就全是套牢盘，难道你要去解放套牢盘吗，不要想着是洗盘什么的，没必要，性价比不高
      </li>
      <li>
        10、如果要左侧买入，最好买那种价格高于日内均线的，或者买入在均线附近上下跳动的
      </li>
      <li>
        11、想想北京炒家，这么大的体量人家都能忍住不动手，所以才走到了今天，管住手这个优点真的要向他学习，再想想你朋友圈的那些人，你真的比他们差吗？不，你是缺少机会，而管住手将会让你的机会更多，赢的几率更大
      </li>
      <li>12、当你在看这些话的时候是不是又想买卖了，忍住</li>
      <li>
        13、亏钱宁愿亏在右侧，也不要亏在左侧，在A股玩股票还是要多玩玩右侧交易
      </li>
      <li>
        14、记住，一切的你想买卖的冲动都是因为情绪，你要学会控制自己，控制自己的情绪
      </li>
      <li>
        15、不一定要分六个票,当行情不好的时候买，最多买三个票，因为要进行战略收缩，你开的票越多，可能亏得越多，所以说行情不好最好买三个票，如果到了行情好的时候可以买六个票，这个时候就会出现东边不亮西边亮的情况，千万别搞反了，比如说行情不好的时候你开六个票，然后想着东边不亮西边亮，这是完全相反的错误的操作，因为行情不好的时候，大部分票都是下跌的，你开的票越多亏的就越多，这个时候一定要进行战略收缩
      </li>
      <li>
        16、玩A股一定要认识到自己的能力，就和打游戏一样有些人他打游戏就是有天赋。但是有些人他打游戏就是不行，这也是一种能力的体现是一种天赋的体现一定要有一个新型的自我认知你可以选择苟分你也可以选择钢枪，如果你没有那个能力就不要选择去钢枪，苟分一样的可以上分，如果只是无脑钢枪，只会死亡然后一落千丈
      </li>
      <li>
        17、个股的走势我们作为小散户是预判不了引导不了的，我们能做的就是在自己的模式内专注自己的手法就行了，在自己的模式内等风来就行了，不用太在意涨跌了，太在意涨跌了真的很难做好，因为他会放大你的情绪，你的情绪放大了你的判断就会出现问题，所以说在自己的模式内等风来就行了
      </li>
      <li>
        18、看到跌的你去抄底觉得他不会在跌了，看到涨的你不敢去追，觉得他不会在涨了，这里本身就存在一个悖论，所以模式很重要，不要太在意涨跌了
      </li>
    </ul>
    <!-- <div class="img_warpper">
      <img
        v-for="(item, index) in imgList"
        :key="index"
        :src="require(`@imgs/${index + 1}.jpeg`)"
      />
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      imgList: new Array(8).fill(1),
      isPlaying: false,
      isLooping: false,
      speed: 0.6,
      utterance: null,
      currentCharIndex: 0
    }
  },
  methods: {
    togglePlay() {
      if (this.isPlaying) {
        speechSynthesis.pause()
      } else {
        if (!this.utterance || speechSynthesis.speaking === false) {
          this.createUtterance()
          speechSynthesis.speak(this.utterance)
        } else {
          speechSynthesis.resume()
        }
      }
      this.isPlaying = !this.isPlaying
    },
    changeSpeed() {
      this.speed = this.speed < 2 ? this.speed + 0.2 : 0.6
      if (this.utterance) {
        this.utterance.rate = this.speed
        if (this.isPlaying) {
          speechSynthesis.cancel()
          this.createUtterance(this.currentCharIndex)
          speechSynthesis.speak(this.utterance)
        }
      }
    },
    resetSpeed() {
      this.speed = 0.6
      if (this.utterance) {
        this.utterance.rate = this.speed
        if (this.isPlaying) {
          speechSynthesis.cancel()
          this.createUtterance(this.currentCharIndex)
          speechSynthesis.speak(this.utterance)
        }
      }
    },
    toggleLoop() {
      this.isLooping = !this.isLooping
    },
    createUtterance(startIndex = 0) {
      const text = this.getPageText().slice(startIndex)
      this.utterance = new SpeechSynthesisUtterance(text)
      this.utterance.rate = this.speed
      this.utterance.onboundary = (event) => {
        if (event.name === 'word') {
          this.currentCharIndex = event.charIndex
        }
      }
      this.utterance.onend = () => {
        if (this.isLooping) {
          this.currentCharIndex = 0
          this.createUtterance()
          speechSynthesis.speak(this.utterance)
        } else {
          this.isPlaying = false
        }
      }
    },
    getPageText() {
      const elements = document.querySelectorAll('.hello ul, .hello h1')
      return Array.from(elements)
        .map((el) => el.textContent.trim())
        .join(' ')
    }
  }
}
</script>

<style scoped>
h1 {
  margin-bottom: 20px;
}
.controls {
  text-align: center;
  margin-bottom: 20px;
}
button {
  margin: 5px;
  padding: 10px 15px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
button:hover {
  background-color: #359769;
}
.img_warpper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}
img {
  width: 300px;
  height: 100%;
  margin-bottom: 60px;
}
</style>
